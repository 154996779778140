@font-face {
  font-family: 'Calibre';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/calibre-medium.woff2") format("woff2");
}

@font-face {
  font-family: 'Tiempos Headline';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/tiempos-headline-light.woff2") format("woff2");
}

@font-face {
  font-family: 'Tiempos Text';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/tiempos-text-regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Tiempos Headline';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/tiempos-headline-light-italic.woff2") format("woff2");
}

@font-face {
  font-family: 'Tiempos Text';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/tiempos-text-regular-italic.woff2") format("woff2");
}
@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "form-inputs";
@import "mixins";
@import "../node_modules/@splidejs/splide/dist/css/splide.min";

@media (hover: hover) {
  .button:hover {
  }
}

/* GLOBAL
---------------------------------------------- */
body {
  font-family: var(--body-font);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 115px;
  @include for-large-tablet-portrait-below {
    scroll-padding-top: 90px;
  }
}
.uniform__potty {
  position: absolute;
  left: -9999px;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
}
.center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.sans-serif {
  font-family: var(--sans-serif-font);
  letter-spacing: 0.05em;
  font-weight: 500;
}
h1 {
  font-family: var(--heading-font);
}
.tiny-font-size,
.tiny-font-size p {
  font-size: var(--tiny-font-size);
}
.xx-small-font-size,
.xx-small-font-size p {
  font-size: var(--xx-small-font-size);
}
.x-small-font-size,
.x-small-font-size p {
  font-size: var(--x-small-font-size);
}
.small-font-size,
.small-font-size p {
  font-size: var(--small-font-size);
}
.medium-font-size,
.medium-font-size p {
  font-size: var(--medium-font-size);
}
.large-font-size,
.large-font-size p {
  font-size: var(--large-font-size);
}
.x-large-font-size,
.x-large-font-size p {
  font-size: var(--x-large-font-size);
}
.xx-large-font-size,
.xx-large-font-size p {
  font-size: var(--xx-large-font-size);
}
.xxx-large-font-size,
.xxx-large-font-size p {
  font-size: var(--xxx-large-font-size);
}
.underlined,
.button a {
  text-decoration-color: var(--black);
  @include underlined-link;
}
.underlined-on-hover a,
a.underlined-on-hover,
.underlined-on-hover button,
button.underlined-on-hover {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: transparent;
  text-decoration-thickness: 0.06em;
  transition: color 0.4s ease, text-decoration-color 0.4s ease;
  &:hover,
  &.active-link {
    text-decoration-color: var(--black);
  }
}
.dark-background {
  .underlined,
  .button a {
    text-decoration-color: var(--white);
  }
  .underlined-on-hover a,
  a.underlined-on-hover {
    &:hover,
    &.active-link {
      text-decoration-color: var(--white);
    }
  }
}
p a {
  color: inherit;
}
.page-width {
  @include page-width;
}
.x-large-width {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--x-large-width);
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
.x-small-margin-bottom {
  margin-bottom: var(--small-vertical-margin);
}
.small-margin-bottom {
  margin-bottom: var(--small-vertical-margin);
}
.medium-margin-bottom {
  margin-bottom: var(--medium-vertical-margin);
}
.large-margin-bottom {
  margin-bottom: var(--large-vertical-margin);
}
.x-large-margin-bottom {
  margin-bottom: var(--x-large-vertical-margin);
}
.xx-large-margin-bottom {
  margin-bottom: var(--xx-large-vertical-margin);
}
.media-left {
  & > div:first-of-type {
    order: 0;
  }
}
.media-right {
  & > div:first-of-type {
    order: 1;
  }
}
/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

.image-wrap {
  background-size: cover;
  min-width: 0;
  background-color: var(--white);
}

/* navbar
---------------------------------------------- */
.page-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  pointer-events: none;
}
.navbar {
  @include page-width;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: var(--lr-page-padding) calc(var(--lr-page-padding)/2) calc(var(--lr-page-padding)*2) var(--lr-page-padding);
  padding: calc(var(--lr-page-padding) / 1.5) calc(var(--lr-page-padding) / 2)
    calc(var(--lr-page-padding) / 1.5) var(--lr-page-padding);
  pointer-events: none;
  &__logo {
    pointer-events: initial;
    svg {
      transition: fill 0.4s ease;
      fill: var(--black);
      height: 26px;
      width: auto;
      @include for-large-tablet-portrait-below {
        height: 20px;
      }
    }
  }
  &__menu {
    display: flex;
    pointer-events: initial;
    @include for-large-tablet-portrait-below {
      display: none;
    }
  }
  &__item {
    transition: color 0.4s ease;
    &--square-link {
      margin: 0 calc(var(--lr-page-padding) / 2);
      border: 1px solid var(--black);
      transition: border-color 0.4s ease;
      .navbar__link {
        padding: 8px 50px;
        transition: color 0.4s ease, background-color 0.4s ease;
        color: var(--black);
        text-decoration: none;
        &:hover {
          background-color: var(--black);
          color: var(--white);
        }
      }
    }
  }
  &__link {
    @include navbar-link;
  }
  &.light-navbar {
    --black: white;
    --white: #20201e;
  }
  &.dark-navbar {
    --black: #20201e;
    --white: white;
  }
  .menu-button {
    @include navbar-link;
    display: none;
    pointer-events: initial;
    @include for-large-tablet-portrait-below {
      display: block;
    }
    svg {
      transition: fill 0.4s ease;
      fill: var(--black);
    }
  }
}
.navbar-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.6s ease;
  background: white;
  pointer-events: none;
  z-index: -1;
  &.active {
    opacity: 1;
  }
}
.language-switcher {
  position: relative;
  display: inline-block;
  &__button {
    cursor: pointer;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    * {
      pointer-events: none;
    }
  }
  &__text {
    display: block;
    width: 0.9rem;
  }
  &__icon {
    height: 0.8rem;
    path {
      transition: 0.4s ease;
      fill: var(--black);
      stroke: var(--black);
    }
  }
  &__content {
    display: none;
    position: absolute;
    margin-left: calc(var(--lr-page-padding) * 0.3);
    min-width: 160px;
    overflow: auto;
    z-index: 1;
    color: inherit;
  }
  .show {
    display: flex;
  }
  &__link {
    @include navbar-link;
    padding: 3px calc(var(--lr-page-padding) * 0.2);
  }
}

/* icon
---------------------------------------------- */
.icon {
  width: 1.3rem;
  height: 1.3rem;
  &--medium-size {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Headshots
---------------------------------------------- */
.circle-head-shot img {
  width: 160px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  height: auto;
  border-radius: 50%;
  filter: grayscale(100%);
}

/* Carousels
---------------------------------------------- */
.splide {
  visibility: inherit;
  &.is-initialized,
  &.is-rendered {
    visibility: inherit;
  }
  &__slide {
    align-self: start;
  }
  &__track {
    height: 100%;
  }
  &__pagination {
    $self: &;
    &__page {
      margin: 5px;
      width: 10px;
      height: 10px;
      &.is-active {
        transform: initial;
      }
    }
    &--upcoming-event-modal {
      top: 0;
      left: 0;
      right: initial;
      position: initial;
      margin-bottom: 0;
      #{ $self }__page {
        &.is-active {
          background: var(--black);
        }
      }
    }
  }
}

/* Uniform form
---------------------------------------------- */
.uniform__potty {
  position: absolute;
  left: -9999px;
}

.form {
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    padding-top: 28vh;
    margin-bottom: var(--medium-vertical-margin);
    @include for-large-tablet-portrait-below {
      grid-template-columns: 1fr;
    }
  }
  &__title {
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
  }
  &__header-right {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    text-align: right;
    margin-top: auto;
    margin-bottom: 0.6rem;
    @include for-large-tablet-portrait-below {
      text-align: initial;
    }
  }
  &__custom-select {
    position: relative;
    select {
      display: none; /*hide original SELECT element:*/
    }
    .select-selected,
    .select-items {
      font-family: var(--heading-font);
      font-size: clamp(6rem, 4.929rem + 2.857vw, 7.5rem);
      @include for-large-tablet-portrait-below {
        font-size: var(--huge-font-size);
      }
    }
    .select-selected-container {
      border-bottom: 1px solid var(--grey);
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .select-selected-chevron {
      margin-bottom: 2rem;
      @include for-large-tablet-portrait-below {
        margin-bottom: 1rem;
      }
      svg {
        width: 33px;
        height: 16.5px;
        @include for-large-tablet-portrait-below {
          width: 20px;
          height: 10px;
        }
      }
    }
    .select-selected {
      width: 100%;
      padding: 8px 0px;
      /*style the arrow inside the select element:*/
      &:after {
        // position: absolute;
        // content: "";
        // top: 14px;
        // right: 10px;
        // width: 0;
        // height: 0;
        // border: 6px solid transparent;
        // border-color: #fff transparent transparent transparent;
      }
      &.select-arrow-active:after {
        // border-color: transparent transparent #fff transparent;
        // top: 7px;
      }
    }
    /*style the items (options), including the selected item:*/
    .select-items div,
    .select-selected {
      // border-bottom: 1px solid var(--grey);
      // border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      cursor: pointer;
      user-select: none;
    }
    /*style items (options):*/
    .select-items {
      // position: absolute;
      // background-color: DodgerBlue;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99;
    }
    .select-items div {
      padding: 8px 0px 0px 0px;
    }
    /*hide the items when the select box is closed:*/
    .select-hide {
      display: none;
    }
    .select-items div:hover,
    .same-as-selected {
      // background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .hidden-form-fields {
    display: none;
  }
  &__inputs-container {
    border-top: 1px solid var(--grey);
    &:not(:first-of-type) {
      margin-top: 3.6rem;
      @include for-small-tablet-below {
        margin-top: 2.25em;
      }
    }
  }
  &__text-input {
    border-bottom: 1px solid var(--grey);
    input {
      font-size: var(--large-font-size);
      padding: 0.3rem 0 0.4rem;
      &:focus-visible {
        outline-color: var(--black);
      }
    }
    ::placeholder {
      font-family: inherit;
      font-size: var(--large-font-size);
      font-weight: inherit;
      color: inherit;
      opacity: 1;
    }
  }
  .submit-wrapper {
    margin-top: var(--medium-vertical-margin);
    .email-form__message {
      margin-top: var(--small-vertical-margin);
      text-align: center;
    }
    &--register {
      display: flex;
      justify-content: space-between;
      margin-top: var(--small-vertical-margin);
      @include for-small-tablet-below {
        flex-direction: column;
        gap: var(--small-vertical-margin);
      }
    }
  }
  .terms-checkbox {
    &__checkbox {
      display: flex;
    }
    label {
      margin-top: 3px;
      margin-left: 0.6rem;
      font-size: var(--x-small-font-size);
    }
    input[type="checkbox"] {
      --border: var(--black);
    }
  }
  .success-container {
    margin-top: var(--small-vertical-margin);
    &--register {
      display: flex;
      justify-content: center;
    }
  }
  input[type="submit"] {
    text-transform: uppercase;
    font-family: var(--sans-serif-font);
    line-height: initial;
    -webkit-appearance: none;
    border-radius: 0px;
    background-color: var(--white);
    border: 1px solid var(--black);
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
    outline: none;
    font-size: 1rem;
    display: inline-block;
    padding: 12px 30px;
    transition: 0.4s ease;
    font-weight: 400;
  }
  .submit-spinner {
    display: none;
    border: 1px solid var(--grey);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border-top-color: var(--black);
    border-left-color: var(--black);
    animation: spin 1s infinite ease-in;
    margin-left: 5px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.new-role.hide {
  display: none;
}
.candidate-requirements {
  margin-top: var(--large-vertical-margin);
  margin-bottom: var(--large-vertical-margin);
  &__container {
    display: flex;
    flex-direction: row;
    @include for-small-tablet-below {
      flex-direction: column;
      gap: var(--small-vertical-margin);
    }
  }
  &__title {
    max-width: 200px;
    margin-right: 3rem;
    font-family: var(--sans-serif-font);
    font-size: var(--medium-font-size);
    text-transform: uppercase;
  }
  &__radio-container {
    display: none;
    flex-grow: 1;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    &.show {
      display: grid;
    }
  }
  &__radio {
    display: flex;
    align-items: start;
    gap: 0.5rem;
    font-size: var(--small-font-size);
  }
}
.message__text {
  max-width: var(--x-large-width);
  margin-top: var(--small-vertical-margin);
  text-align: center;
}
.apply-footer {
  font-family: var(--sans-serif-font);
  text-transform: uppercase;
  font-size: var(--x-small-font-size);
  a {
    color: var(--darkgrey);
    text-decoration: underline;
    text-underline-position: intial;
    text-decoration-thickness: 0.06em;
    transition: text-decoration-color 0.4s ease;
    &:hover {
      text-decoration-color: transparent;
    }
  }
}

/* local page
---------------------------------------------- */
.location-template .background-gradient {
  padding-top: var(--medium-vertical-margin);
  padding-bottom: var(--medium-vertical-margin);
  margin-bottom: var(--x-large-vertical-margin);
}
.local-events {
  margin-bottom: var(--large-vertical-margin);
  .blog-category-section {
    padding-bottom: var(--medium-vertical-margin);
  }
  &__coming-events {
    font-family: var(--sans-serif-font);
    font-size: var(--preheading-font-size);
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey);
    padding-bottom: var(--x-small-vertical-margin);
  }
  .square-button--view-all-events {
    margin-top: var(--medium-vertical-margin);
  }
}
.local-articles {
  margin-bottom: var(--medium-vertical-margin);
  .blog-category-section {
    padding-bottom: var(--medium-vertical-margin);
    &__title-container {
      margin-bottom: var(--medium-vertical-margin);
    }
  }
  &__recent-articles {
    font-family: var(--sans-serif-font);
    font-size: var(--preheading-font-size);
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey);
    padding-bottom: var(--x-small-vertical-margin);
  }
  .square-button--view-all-events {
    margin-top: var(--medium-vertical-margin);
  }
}
/* local page form
---------------------------------------------- */
.local-form {
  margin-bottom: var(--small-vertical-margin);
  &__title {
    font-size: var(--xxx-large-font-size);
    font-family: var(--heading-font);
    text-align: center;
    margin-bottom: var(--large-vertical-margin);
  }
  &__intro {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    font-size: var(--preheading-font-size);
    margin-bottom: var(--small-vertical-margin);
  }
}
.role-accordion {
  // border-top: 1px solid var(--grey);
  &__container {
    border-bottom: initial !important;
  }
  &__title {
    font-size: var(--large-font-size);
    padding: 0.3rem 0 0.4rem;
  }
  &__radio-container {
    margin: var(--x-small-vertical-margin) 0;
    font-size: var(--medium-font-size);
  }
  &__header {
    margin-top: var(--x-small-vertical-margin);
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__radio {
    padding-left: 3px;
  }
}

/* anchor-menu
---------------------------------------------- */
.anchor-menu {
  &__container {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--grey);
    @include for-small-tablet-below {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      width: 100%;
      justify-content: initial;
    }
  }
  a {
    color: inherit;
    padding: 1.5rem 2rem;
    display: block;
    font-family: var(--sans-serif-font);
    font-size: var(--xx-small-font-size);
    text-transform: uppercase;
    @include for-small-tablet-below {
      padding: 1.5rem 0.7rem;
      &:first-of-type {
        padding-left: 0;
      }
    }
  }
}
/* Mobile menu
---------------------------------------------- */
.menu-modal {
  $self: &;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  height: 100svh;
  z-index: 99;
  background-color: var(--grey);
  transition: right 0.4s ease-in-out;
  color: var(--darkgrey);
  overflow: scroll;
  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    color: var(--black);
  }
  &__logo {
    padding: var(--lr-page-padding);
  }
  &__close {
    display: block;
    padding: var(--lr-page-padding);
    text-transform: uppercase;
    line {
      stroke: var(--black);
    }
  }
  &__body {
    padding: var(--large-vertical-margin) var(--lr-page-padding)
      var(--medium-vertical-margin) var(--lr-page-padding);
    max-width: 300px;
    margin: 0 auto;
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
    display: flex;
    flex-direction: column;
    height: calc(100% - 68px);
    height: calc(100svh - 68px);
    justify-content: space-between;
  }
  &__main-menu {
    #{ $self }__item {
      &.navbar__item--square-link {
        margin: var(--small-vertical-margin) 0;
      }
      a {
        display: block;
        padding: 0.3rem 0;
        text-align: center;
        font-family: var(--sans-serif-font);
        font-size: var(--preheading-font-size);
        text-transform: uppercase;
        color: var(--darkgrey);
      }
    }
  }
  &__secondary-menu {
    #{ $self }__item {
      &.navbar__item--square-link {
        margin: var(--small-vertical-margin) 0;
      }
      a {
        display: block;
        padding: 0.3rem 0;
        text-align: center;
        color: var(--darkgrey);
        text-decoration: underline;
        font-size: var(--small-font-size);
      }
    }
  }
  &__language-switcher {
    display: flex;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    justify-content: center;
    margin-top: var(--large-vertical-margin);
    gap: 1rem;
    .language-switcher__link {
      font-size: var(--small-font-size);
    }
  }
  &__socials-wrapper {
    margin-top: var(--small-vertical-margin);
    padding-bottom: var(--small-vertical-margin);
  }
  &__socials-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  &__social-icon {
    display: flex;
    padding: 0.5rem;
  }
  &.hidden {
    right: -100%;
  }
  &.active {
    right: 0;
  }
}

/* footer
---------------------------------------------- */
.footer-container {
  background: var(--black);
  color: var(--white);
  padding: var(--large-vertical-margin) 0 calc(var(--lr-page-padding) * 1);
  .footer-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--lr-page-padding);
    margin-bottom: var(--large-vertical-margin);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
      text-align: center;
    }
    a {
      padding: 0.2rem 0;
      display: block;
      font-size: var(--medium-font-size);
      color: inherit;
    }
  }
  .policies-links {
    margin-top: 2.5rem;
    &__container {
      display: flex;
      justify-content: center;
      &--stacked {
        @include for-small-tablet-below {
          flex-direction: column;
          text-align: center;
        }
      }
      a,
      .copyright {
        display: block;
        padding: 0.5rem calc(var(--lr-page-padding) / 3);
        color: inherit;
        font-size: var(--small-font-size);
      }
    }
    &.policies-links--increased-margins {
      margin-top: 2.6rem;
      margin-bottom: 0.5rem;
    }
  }
  .footer-email a {
    color: inherit;
    font-size: 1.2rem;
  }
  .footer-address {
    @include for-small-tablet-below {
      text-align: center;
    }
  }
}

/* square-button
---------------------------------------------- */
.square-button {
  display: flex;
  justify-content: center;
  font-family: var(--sans-serif-font);
  &--author-portal {
    gap: var(--medium-grid-gap);
    @include for-small-tablet-below {
      flex-direction: column;
    }
  }
  &__link {
    display: inline-block;
    padding: 12px 30px;
    transition: 0.4s ease;
    background-color: var(--grey);
    border: 1px solid var(--grey);
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
    outline: none;
    font-size: 1rem;
    &--with-icon {
      @include for-small-tablet-below {
        text-align: center;
      }
    }
    &:hover {
      background-color: transparent;
      border: 1px solid var(--black);
    }
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 1px solid var(--black);
      outline-offset: 1px;
    }
    &--white {
      background-color: var(--white);
      border: 1px solid var(--black);
      &:hover {
        background-color: var(--black);
        border: 1px solid var(--black);
        color: var(--white);
      }
    }
  }
  &__icon {
    display: inline;
    margin-right: 0.5rem;
    vertical-align: bottom;
  }
}

/* preheading
---------------------------------------------- */
.preheading {
  margin-bottom: var(--small-vertical-margin);
  font-family: var(--sans-serif-font);
  font-size: var(--preheading-font-size);
  text-transform: uppercase;
  color: var(--darkgrey);
}

/* background
---------------------------------------------- */
.background-color {
  padding-top: var(--large-vertical-margin);
  padding-bottom: var(--medium-vertical-margin);
  &--cream {
    background-color: var(--cream);
  }
}

/* background-gradient
---------------------------------------------- */
.background-gradient {
  padding-top: var(--large-vertical-margin);
  padding-bottom: var(--medium-vertical-margin);
  --border-color: var(--darkgrey);
  &--cream,
  &--white-to-cream {
    background: linear-gradient(
      180deg,
      rgba(229, 228, 224, 0) 0%,
      #e5e4e0 21.35%,
      rgba(223, 223, 221, 0.503759) 79.17%,
      rgba(229, 228, 224, 0) 100%
    );
    .square-button__link--white {
      border-color: var(--white);
      &:hover {
        border-color: var(--black);
      }
    }
  }
  &--white-to-cream {
    background: linear-gradient(
      180deg,
      rgba(229, 228, 224, 0) 0%,
      rgba(229, 228, 224, 1) 100%
    );
  }
}

/* blog-container 
---------------------------------------------- */
.blog-container {
  margin-bottom: var(--small-vertical-margin);
}

/* blog-banner
---------------------------------------------- */
.blog-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-top: 28vh;
  margin-bottom: var(--large-vertical-margin);
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
  }
  @include for-phone-below {
    margin-bottom: var(--medium-vertical-margin);
  }
  &__title-container {
  }
  &__title {
    font-family: var(--heading-font);
    font-size: var(--huge-font-size);
  }
  &__description {
    font-size: var(--medium-font-size);
    margin-bottom: var(--small-vertical-margin);
  }
  &__category-links {
    display: flex;
    justify-content: space-between;
    gap: var(--lr-page-padding);
    @include for-small-tablet-below {
      justify-content: initial;
    }
    @include for-phone-below {
      flex-direction: column;
      gap: var(--xs-small-vertical-margin);
    }
  }
  &__category-link {
    text-transform: uppercase;
    color: var(--darkgrey);
    padding: 0.5rem 0;
    font-family: var(--sans-serif-font);
    font-size: var(--x-small-font-size);
  }
}

/* latest-articles
---------------------------------------------- */
.latest-articles {
  padding-bottom: var(--large-vertical-margin);
  border-bottom: 1px solid var(--grey);
  &__heading-container {
    padding-bottom: var(--x-small-vertical-margin);
    border-bottom: 1px solid var(--grey);
    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
      align-items: end;
      @include for-large-tablet-portrait-below {
        grid-template-columns: 1fr;
      }
      @include for-phone-below {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: var(--medium-vertical-margin);
      }
    }
  }
  &__heading {
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
  }
  &__category-links {
    display: flex;
    justify-content: space-between;
    @include for-large-tablet-portrait-below {
      justify-content: start;
      gap: var(--lr-page-padding);
    }
    @include for-phone-below {
      flex-direction: column;
      gap: var(--xs-small-vertical-margin);
    }
  }
  &__category-link {
    text-transform: uppercase;
    color: var(--darkgrey);
    padding: 0.5rem 0;
    font-family: var(--sans-serif-font);
    font-size: var(--x-small-font-size);
  }
}
/* Module */
.latest-articles-module {
  &__title-container {
    margin-bottom: calc(var(--medium-vertical-margin) + 1rem);
    @include for-phone-below {
      margin-bottom: var(--small-vertical-margin);
    }
  }
  &__title {
    font-size: var(--xxx-large-font-size);
    font-family: var(--heading-font);
    margin-bottom: var(--small-vertical-margin);
  }
  &__title-paragraph {
    font-size: var(--large-font-size);
  }
  .latest-articles {
    padding-bottom: var(--medium-vertical-margin);
  }
}
/* cards
---------------------------------------------- */
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem 1.2rem;
  margin-top: var(--small-vertical-margin);
  @include for-large-tablet-portrait-below {
    grid-template-columns: 1fr 1fr;
  }
  @include for-phone-below {
    grid-template-columns: 1fr;
  }
}
.card {
  &__image {
    aspect-ratio: 1 / 1.25;
    object-fit: cover;
  }
  &__theme {
    margin: 1.5rem 0 1.5rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__title-wrapper {
    text-decoration: none;
    color: var(--black);
  }
  &__title {
    font-size: var(--medium-font-size);
    margin-bottom: 1.2rem;
    @include for-small-tablet-below {
      font-size: var(--large-font-size);
    }
  }
  &__length {
    margin-top: 1.5rem;
    color: var(--darkgrey);
    font-family: var(--sans-serif-font);
    text-transform: lowercase;
  }
  &__date {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    font-size: var(--small-font-size);
  }
}

/* featured-article
---------------------------------------------- */
.featured-article {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-bottom: var(--large-vertical-margin);
  border-bottom: 1px solid var(--grey);
  @include for-phone-below {
    grid-template-columns: 1fr;
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__heading {
    font-family: var(--heading-font);
    font-size: var(--huge-font-size);
  }
  &__theme {
    margin: 3rem 0 3rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__title-wrapper {
    text-decoration: none;
    color: var(--black);
  }
  &__title {
    font-size: var(--large-font-size);
    margin-bottom: 1.2rem;
  }
  &__excerpt {
    font-size: var(--small-font-size);
  }
  &__button {
    display: inline-block;
    color: var(--black);
    margin-top: 1rem;
    padding: 0.2rem 0;
  }
  &__image {
    aspect-ratio: 1 / 1.25;
    object-fit: cover;
  }
}

/* blog-category-section
---------------------------------------------- */
.blog-category-section {
  margin-top: var(--large-vertical-margin);
  border-bottom: 1px solid var(--grey);
  &__title-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    margin-bottom: var(--large-vertical-margin);
    @include for-phone-below {
      grid-template-columns: 1fr;
    }
  }
  &__title {
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
  }
  &__description {
    font-size: var(--medium-font-size);
  }
  &__view-all-container {
    margin-top: var(--large-vertical-margin);
  }
  &__view-all-text {
    font-family: var(--sans-serif-font);
    @include for-phone-below {
      text-align: center;
      padding-bottom: var(--small-vertical-margin);
    }
  }
  &__view-all-button {
    margin-left: 1rem;
    color: inherit;
    text-transform: uppercase;
    display: inline-block;
    padding: var(--x-small-vertical-margin);
    @include for-phone-below {
      margin-left: initial;
    }
    &--event {
      margin-left: 0rem;
      padding: var(--x-small-vertical-margin) 0;
    }
  }
}

/* blog-filters
---------------------------------------------- */
.blog-filters {
  &__container {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 0.4rem;
  }
  &__item:not(:last-of-type) {
    margin-right: 2.5rem;
  }
  &__link {
    color: var(--darkgrey);
    text-decoration: none;
    font-family: var(--sans-serif-font);
    font-size: var(--x-small-font-size);
    &:hover {
      text-decoration-color: var(--darkgrey) !important;
    }
  }
}

/* blog-category-banner
---------------------------------------------- */
.blog-category-banner {
  padding-top: 28vh;
  &__title {
    font-family: var(--heading-font);
    font-size: var(--huge-font-size);
  }
  &__theme {
    font-size: 0.6em;
  }
  &__filters {
    margin-top: var(--large-vertical-margin);
  }
}
.blog-category-cards {
  margin-bottom: var(--large-vertical-margin);
}

/* article-banner
---------------------------------------------- */
.article-header {
  &--half-image,
  &--padded-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
    }
  }
  &--padded-image {
    height: 100vh;
    @include for-small-tablet-below {
      height: initial;
      min-height: 100vh;
      min-height: 100svh;
    }
  }
  &--full-image {
    position: relative;
  }
  &__image-content {
    &--padded-image {
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-small-tablet-below {
        height: 50vh;
        height: 50svh;
        padding-top: 12vh;
        padding-top: 12svh;
      }
    }
  }
  &__image {
    object-fit: cover;
    &--half-image,
    &--full-image {
      height: 100vh;
      width: 100%;
    }
    &--full-image {
      @include for-small-tablet-below {
        height: 100vh;
        height: 100svh;
      }
    }
    &--half-image {
      @include for-small-tablet-below {
        height: 50vh;
        height: 50svh;
      }
    }
    &--padded-image {
      width: 50%;
      height: auto;
      aspect-ratio: 1 / 1.2;
      @include for-small-tablet-below {
        width: auto;
        height: 100%;
      }
    }
  }
  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    .article-theme {
      display: block;
      margin: 3rem 0 3rem;
      font-family: var(--sans-serif-font);
      text-transform: uppercase;
      text-decoration: none;
      color: var(--white);
      @include for-small-tablet-below {
        margin: 2rem 0 2rem;
      }
    }
    .article-title {
      font-size: var(--x-large-font-size);
    }
    .article-length {
      margin-top: 1.5rem;
      font-family: var(--sans-serif-font);
      text-transform: uppercase;
    }
    &--half-image,
    &--padded-image {
      @include for-small-tablet-below {
        justify-content: initial;
        min-height: 50vh;
        min-height: 50svh;
        .article-header__banner-text-container {
          height: 100%;
          gap: 1rem;
        }
        .article-theme {
          text-align: center;
          margin-top: 1rem;
        }
        .article-title {
          max-width: var(--x-large-width);
          text-align: center;
        }
        .article-length {
          margin-top: var(--medium-vertical-margin);
          text-align: center;
          @include for-small-tablet-below {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
    &--half-image {
      background-color: var(--black);
      color: var(--white);
    }
    &--padded-image {
      .article-theme {
        color: var(--black);
      }
      &__banner-text-heading {
        @include for-small-tablet-below {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &--full-image {
      align-items: center;
      color: var(--white);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .article-theme {
        text-align: center;
        @include for-small-tablet-below {
          margin-top: initial;
        }
      }
      .article-title {
        max-width: var(--x-large-width);
        text-align: center;
      }
      .article-length {
        margin-top: var(--medium-vertical-margin);
        text-align: center;
        @include for-small-tablet-below {
          margin-top: auto;
        }
      }
    }
  }
  &__banner-text-container {
    padding: 2.5rem 3rem;
    @include for-small-tablet-below {
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

/* article-intro
---------------------------------------------- */
.article-intro {
  margin-top: var(--medium-vertical-margin);
  &__name {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    text-align: center;
    color: var(--darkgrey);
    font-size: var(--preheading-font-size);
  }
  &__date {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    text-align: center;
  }
  &__excerpt {
    margin: var(--medium-vertical-margin) auto var(--small-vertical-margin);
    font-size: var(--medium-font-size);
    text-align: center;
    max-width: var(--x-large-width);
    line-height: var(--standard-line-height);
  }
}

/* block-content
---------------------------------------------- */
.block-content {
  font-size: var(--small-font-size);
  max-width: var(--large-width);
  &--event {
    font-size: var(--x-small-font-size);
  }
  &--article {
    margin: var(--medium-vertical-margin) auto var(--small-vertical-margin);
  }
}

/* share
---------------------------------------------- */
.share {
  margin-top: var(--medium-vertical-margin);
  padding-bottom: var(--x-small-vertical-margin);
  display: flex;
  align-items: center;
  gap: 1rem;
  &__heading {
    font-family: var(--sans-serif-font);
    font-size: var(--small-font-size);
    text-transform: uppercase;
  }
  &__icons {
    display: flex;
  }
  &__icon {
    display: flex;
    padding: 0.5rem;
  }
}
/* article-author-section
---------------------------------------------- */
.article-author-section {
  margin-bottom: var(--medium-vertical-margin);
  &__container {
    padding-top: var(--medium-vertical-margin);
    border-top: 1px solid var(--grey);
  }
  &__circle-image {
    margin-bottom: var(--small-vertical-margin);
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .partner-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/* related-articles
---------------------------------------------- */
.related-articles {
  background-color: var(--cream);
  padding-top: var(--medium-vertical-margin);
  padding-bottom: var(--medium-vertical-margin);
  &__heading {
    margin-bottom: var(--medium-vertical-margin);
    font-family: var(--sans-serif-font);
    font-size: var(--small-font-size);
  }
}

/* event 
---------------------------------------------- */
.event {
  // Modal
  &--modal {
    .event-header {
      padding-top: 0;
    }
  }
  &--modal-multiple {
    .event-header {
      padding-top: var(--small-vertical-margin);
    }
  }
}
.event-header {
  padding-top: var(--large-vertical-margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text-content {
    margin-bottom: var(--medium-vertical-margin);
    text-align: center;
    font-family: var(--heading-font);
  }
  &__theme {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    margin-bottom: var(--small-vertical-margin);
  }
  &__title {
    font-size: var(--x-large-font-size);
    max-width: var(--x-large-width);
  }
  &__subheading {
    margin-top: var(--x-small-vertical-margin);
    font-size: var(--large-font-size);
  }
  &__image-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--medium-grid-gap);
    margin-bottom: var(--medium-vertical-margin);
    @include for-phone-below {
      gap: calc(var(--medium-grid-gap) * 1.3);
      flex-wrap: initial;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include for-phone-below {
    .circle-head-shot img {
      width: 150px;
    }
  }
}
.event-contents {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: var(--medium-grid-gap);
  margin-bottom: var(--medium-vertical-margin);
  @include for-large-tablet-portrait-below {
    grid-template-columns: initial;
    grid-template-areas:
      "b b"
      "c c";
  }
  @include for-phone-below {
    grid-template-areas:
      "b"
      "c";
  }
}
.event-contents--single-column {
  grid-template-columns: 1fr;
  .block-content--event {
    margin-left: auto;
    margin-right: auto;
  }
  .event-details {
    display: none;
  }
}
.event-text-content {
  @include for-large-tablet-portrait-below {
    grid-area: c;
  }
  &__blocks {
    margin-top: 1em;
  }
}
.event-intro {
  &--mobile {
    margin-bottom: var(--medium-vertical-margin);
    display: none;
    @include for-large-tablet-portrait-below {
      display: block;
    }
  }
  &--desktop {
    @include for-large-tablet-portrait-below {
      display: none;
    }
  }
  &__text {
    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
.event-facilitators {
  &__title {
    font-size: var(--x-small-font-size);
    font-style: italic;
    margin-bottom: calc(var(--x-small-vertical-margin) * 1.2);
    text-align: center;
    width: 250px;
    @include for-phone-below {
      width: 300px;
    }
  }
  &__facilitator {
    margin-top: var(--x-small-vertical-margin);
    text-align: center;
  }
  &__name {
    font-size: var(--x-small-font-size);
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    text-align: center;
    width: 250px;
    @include for-phone-below {
      width: 300px;
    }
  }
  &__role {
    font-size: var(--x-small-font-size);
    text-align: center;
    width: 250px;
    @include for-phone-below {
      width: 300px;
    }
  }
}
.event-details {
  align-self: start;
  position: sticky;
  top: 8rem;
  @include for-large-tablet-portrait-below {
    grid-area: b;
    align-self: initial;
    position: initial;
    top: initial;
  }
  &__date {
    font-size: var(--x-small-font-size);
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__times {
    margin-top: var(--x-small-vertical-margin);
    font-size: var(--x-small-font-size);
  }
  &__time {
    font-size: var(--xx-small-font-size);
    margin-top: var(--x-small-vertical-margin);
  }
  &__register-button {
    margin-top: calc(var(--small-vertical-margin) * 0.6);
    justify-content: start;
  }
}
.event-register {
  margin-bottom: var(--medium-vertical-margin);
  &__container {
    border-top: 1px solid var(--grey);
    padding-top: var(--medium-vertical-margin);
  }
  &__title {
    margin-bottom: var(--medium-vertical-margin);
    font-size: var(--x-large-font-size);
    font-family: var(--heading-font);
    text-align: center;
  }
}

/* upcoming-event-modal
---------------------------------------------- */
.upcoming-event-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  cursor: pointer;
  &__wrapper {
    background-color: var(--white);
    position: absolute;
    overflow: scroll;
    width: calc(100% - calc(var(--lr-page-padding) * 2));
    max-height: calc(100vh - calc(var(--lr-page-padding) * 2));
    max-height: calc(100svh - calc(var(--lr-page-padding) * 2));
    cursor: initial;
    @include for-small-tablet-below {
      width: 80%;
      transform: initial;
    }
    @include for-phone-below {
      width: 90%;
    }
  }
  &__container {
    height: 100%;
  }
  &__content {
    padding: 0 calc(var(--lr-page-padding) * 1.5) var(--lr-page-padding)
      calc(var(--lr-page-padding) * 1.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__close {
    position: sticky;
    top: 5px;
    padding: 15px;
    left: 100%;
    cursor: pointer;
    line {
      stroke: var(--black);
    }
  }
  .page-width {
    padding-left: initial !important;
    padding-right: initial !important;
  }
  .event-contents {
    margin-bottom: initial;
  }
}

/* MODULES
----------------------------------------------
----------------------------------------------
---------------------------------------------- */

/* Accordion
---------------------------------------------- */
.accordion-module {
  .square-button {
    margin-top: var(--medium-vertical-margin);
  }
}
.accordion {
  $self: &;
  border-top: 1px solid var(--grey);
  &:last-of-type {
    padding-bottom: var(--bottom-page-padding);
    border-bottom: 1px solid var(--grey);
  }
  &__button {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 0.5rem;
    padding: 0.3rem 0 0.4rem;
    font-size: var(--large-font-size);
    text-align: left;
    width: 100%;
    color: var(--black);
    border: none;
    background: none;
    outline: none;
    &:hover,
    &:focus {
      cursor: pointer;
    }
    .read-less {
      display: none;
    }
    &[aria-expanded="true"] {
      .read-more {
        display: none;
      }
      .read-less {
        display: block;
      }
      + #{ $self }__content-wrapper {
        opacity: 1;
        max-height: 60em;
        transition: all 400ms linear;
        will-change: opacity, max-height;
      }
    }
  }
  &__button-indicator {
    font-family: var(--sans-serif-font);
    font-size: var(--x-small-font-size);
    text-transform: uppercase;
    flex-shrink: 0;
  }
  &__content-wrapper {
    border-top: 1px solid var(--grey);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 400ms linear, max-height 400ms linear;
    will-change: opacity, max-height;
  }
  &__content {
    margin-top: 2rem;
    font-size: var(--medium-font-size);
    max-width: var(--x-large-width);
    margin-left: auto;
    margin-bottom: 2rem;
    > :not(:last-child) {
      margin-bottom: 1em;
    }
    ul,
    ol {
      list-style: initial;
      // padding-inline-start: 1em;
      li::marker {
        font-size: 1em;
      }
    }
    &--two-columns {
      columns: 2;
      column-gap: 2rem;
    }
  }
}

/* Large Accordion
---------------------------------------------- */
.accordion {
  $self: &;
  &--large {
    &:first-of-type {
      border-top: initial;
    }
    #{ $self }__button {
      font-size: var(--huge-font-size);
      @include for-small-tablet-below {
        font-size: var(--x-large-font-size);
      }
    }
    #{ $self }__content {
      max-width: 100%;
      margin-left: auto;
      margin-bottom: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--medium-grid-gap);
      @include for-small-tablet-below {
        grid-template-columns: 1fr;
        gap: calc(var(--medium-grid-gap) * 0.5);
      }
      .square-button {
        justify-content: start;
      }
    }
    #{ $self }__content-group {
      margin-bottom: var(--small-vertical-margin);
      .preheading {
        margin-bottom: var(--x-small-vertical-margin);
        color: var(--black);
      }
    }
  }
}

/* centered-large-paragraph
---------------------------------------------- */
.centered-large-paragraph {
  font-family: var(--heading-font);
  text-align: center;
  a {
    color: var(--darkgrey);
    font-style: italic;
    @include underlined-link;
  }
}

/* cta
---------------------------------------------- */
.cta {
  text-align: center;
  &__heading {
    margin-bottom: var(--small-vertical-margin);
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
    padding: 0 2rem;
    @include for-phone-below {
      padding: 0 0;
    }
    &--x-large-width {
      max-width: var(--x-large-width);
      margin-left: auto;
      margin-right: auto;
    }
    &--xx-large-width {
      max-width: var(--xx-large-width);
      margin-left: auto;
      margin-right: auto;
    }
    &.xxx-large-font-size {
      font-size: var(--xx-large-font-size);
    }
    &.medium-font-size {
      font-size: var(--large-font-size);
    }
  }
  &__paragraph {
    margin-bottom: var(--medium-vertical-margin);
    font-size: var(--medium-font-size);
    padding: 0 2rem;
    line-height: var(--standard-line-height);
    @include for-phone-below {
      padding: 0 0;
    }
    &--x-large-width {
      max-width: var(--x-large-width);
      margin-left: auto;
      margin-right: auto;
    }
    &--xx-large-width {
      max-width: var(--xx-large-width);
      margin-left: auto;
      margin-right: auto;
    }
    &.large-font-size {
      font-family: var(--heading-font);
      padding-bottom: 1em;
    }
    a {
      color: var(--darkgrey);
    }
  }
}

/* circle-with-text
---------------------------------------------- */
.circle-with-text {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffffff 51.56%,
      #e5e4e0 100%
    );
  }
  &__titles {
    text-align: center;
    font-family: var(--heading-font);
    font-size: var(--medium-font-size);
    font-style: italic;
  }
}
/* complex-hover
---------------------------------------------- */
.complex-hover {
  &__buttons-row {
    margin-bottom: var(--medium-vertical-margin);
  }
  &__tabs-row-wrap {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @include for-phone-below {
      grid-template-columns: 1fr;
    }
  }
  &__tabs-column-a {
    grid-column: 1 / span 5;
    @include for-phone-below {
      grid-column: initial;
    }
  }
  &__tabs-column-b {
    grid-column: 7 / span 6;
    font-size: var(--small-font-size);
    padding-top: 2rem;
    line-height: var(--standard-line-height);
    @include for-phone-below {
      grid-column: initial;
    }
    .square-button {
      margin-top: var(--small-vertical-margin);
      justify-content: start;
      &__link--white {
        background-color: transparent;
        &:hover {
          background-color: var(--black);
        }
      }
    }
  }
  li:not(:last-of-type) {
    margin-bottom: 0.2em;
  }
}

/* Complex hover background color
---------------------------------------------- */
.background-hover-color {
  position: relative;
  transition: all 1s ease;
  &[data-bg="green"] {
    background: rgba(63, 127, 101, 1) 100%;
  }
  &[data-bg="blue"] {
    background: rgba(139, 191, 215, 1) 100%;
  }
  &[data-bg="purple"] {
    background: rgba(180, 183, 206, 1) 100%;
  }
  &[data-bg="orange"] {
    background: rgba(191, 110, 38, 1) 100%;
  }
  &[data-bg="cream"] {
    background: rgba(230, 232, 227, 1) 100%;
  }
  &[data-bg="lightgreen"] {
    background: rgba(186, 199, 135, 1) 100%;
  }
  &[data-bg="lightgrey"] {
    background: rgba(181, 178, 181, 1) 100%;
  }
  &[data-bg="darkgrey"] {
    background: rgba(87, 87, 87, 1) 100%;
  }
  &[data-bg="green"],
  &[data-bg="blue"],
  &[data-bg="purple"],
  &[data-bg="orange"],
  &[data-bg="cream"],
  &[data-bg="lightgreen"],
  &[data-bg="lightgrey"],
  &[data-bg="darkgrey"] {
    --darkgrey: #20201e;
    .module__left-aligned-large-paragraph:first-of-type,
    .text-banner,
    .form__header {
      color: var(--white);
    }
    .form__custom-select .select-selected {
      border-color: var(--white);
    }
    .select-selected-chevron {
      path {
        stroke: var(--white);
      }
    }
  }
  & > * {
    z-index: 1;
    position: relative;
  }
}
.fake-gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    360deg,
    hsl(0, 100%, 100%) 0%,
    hsla(0, 100%, 100%, 0.738) 19%,
    hsla(0, 100%, 100%, 0.541) 34%,
    hsla(0, 100%, 100%, 0.382) 47%,
    hsla(0, 100%, 100%, 0.278) 56.5%,
    hsla(0, 100%, 100%, 0.194) 65%,
    hsla(0, 100%, 100%, 0.126) 73%,
    hsla(0, 100%, 100%, 0.075) 80.2%,
    hsla(0, 100%, 100%, 0.042) 86.1%,
    hsla(0, 100%, 100%, 0.021) 91%,
    hsla(0, 100%, 100%, 0.008) 95.2%,
    hsla(0, 100%, 100%, 0.002) 98.2%,
    hsla(0, 100%, 100%, 0) 100%
  );
  z-index: 0 !important;
}

/* hover-buttons */
.hover-buttons {
  &__item {
    &:not(:last-of-type) {
      margin-bottom: 0.8rem;
    }
    &--small-bottom-margin:not(:last-of-type) {
      margin-bottom: 0.4rem;
    }
  }
  &__heading {
    display: inline;
    font-family: var(--heading-font);
    font-size: var(--xxx-large-font-size);
    line-height: 1;
    transition: color 0.4s ease;
    color: var(--grey);
    text-align: left;
    text-decoration: underline;
    text-underline-position: intial;
    text-decoration-thickness: 0.06em;
    text-decoration-color: var(--grey);
    &--small {
      font-size: var(--large-font-size);
    }
    &:hover {
      cursor: default;
    }
    &.menu-active {
      color: var(--black);
      text-decoration-color: var(--black);
    }
  }
}

/* hero
---------------------------------------------- */
.hero {
  &__wrap {
    position: relative;
  }
  &__image {
    width: 100%;
    background-color: var(--black);
    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 88vh;
      display: block;
      background-color: var(--cream);
      @include for-small-tablet-below {
        min-height: 88vh;
        min-height: 88svh;
      }
    }
    &--tall {
      img,
      video {
        height: 100vh;
        @include for-small-tablet-below {
          min-height: 100vh;
          min-height: 100svh;
        }
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -45%);
    color: var(--white);
    @include for-small-tablet-below {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .h1 {
      max-width: 800px;
      margin-left: 0;
      margin-right: 0;
      font-size: var(--xx-large-font-size);
    }
    p {
      margin-top: 2rem;
      max-width: var(--xx-large-width);
      font-size: var(--large-font-size);
    }
    &--tall {
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--xxx-large-font-size);
      @include for-small-tablet-below {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

/* left-aligned-large-paragraph
---------------------------------------------- */
.left-aligned-large-paragraph {
  padding-right: 2rem;
  font-family: var(--heading-font);
  @include for-phone-below {
    padding-right: var(--lr-page-padding);
  }
  a {
    color: var(--darkgrey);
    font-style: italic;
    @include underlined-link;
  }
}

/* growing-dots
---------------------------------------------- */
.growing-dots {
  display: flex;
  gap: calc(var(--large-grid-gap) * 1.2);
  align-items: flex-end;
  @include for-phone-below {
    gap: var(--medium-grid-gap);
  }
  &__dot {
    aspect-ratio: 1 / 1;
    background: var(--grey);
    border-radius: 50%;
    width: 100%;
    height: auto;
    flex-basis: 5%;
    &:nth-of-type(2) {
      flex-basis: 10%;
    }
    &:nth-of-type(3) {
      flex-basis: 15%;
    }
    &:nth-of-type(4) {
      flex-basis: 20%;
    }
    &:nth-of-type(5) {
      flex-basis: 40%;
    }
  }
}
/* line
---------------------------------------------- */
.line {
  border-top: 1px solid var(--grey);
}
/* logos-animation
---------------------------------------------- */
.logo-animation-container {
  overflow: hidden;
}
.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  /* &:not(:last-of-type) {
    margin-bottom: var(--medium-vertical-margin);
  } */
  @include for-small-tablet-below {
    gap: var(--gap);
  }
  &__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
    img {
      height: 45px;
      width: auto;
      @include for-small-tablet-below {
        height: 25px;
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

.marquee svg {
  display: grid;
  place-items: center;
  width: var(--size);
  fill: var(--color-text);
  background: var(--color-bg-accent);
  aspect-ratio: 16/9;
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;
}

.marquee--vertical svg {
  aspect-ratio: 1;
  width: calc(var(--size) / 1.5);
  padding: calc(var(--size) / 6);
}

.logo-animation-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

/* one-column-image
---------------------------------------------- */
.one-column-image {
  img,
  video {
    width: 100%;
    height: 700px;
    object-fit: cover;
    @include for-small-tablet-below {
      height: 50vw;
    }
  }
}

/* partner-info
---------------------------------------------- */
.partner-info {
  &__name {
    margin-bottom: 1rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    font-size: var(--small-font-size);
    text-decoration: initial;
    color: inherit;
  }
  &__type,
  &__location {
    font-size: var(--x-small-font-size);
  }
  &__linkedin {
    display: block;
    margin-top: 0.7rem;
  }
}

/* scroll-hover-animation
---------------------------------------------- */
.scroll-hover-animation {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
    }
  }
  &__column-a {
    grid-column: 1 / span 5;
    @include for-small-tablet-below {
      grid-column: inherit;
    }
    padding-top: calc(var(--x-large-vertical-margin) - 1rem);
  }
  &__buttons-column {
    padding-top: var(--large-vertical-margin);
    @include for-small-tablet-below {
      padding-top: var(--small-vertical-margin);
    }
  }
  &__buttons-footer {
    margin-top: var(--small-vertical-margin);
    font-size: var(--medium-font-size);
  }
  &__column-b {
    grid-column: 7 / span 6;
    @include for-small-tablet-below {
      grid-column: inherit;
    }
    align-self: start;
    // position: sticky;
    // top: var(--large-vertical-margin);
    padding-top: var(--x-small-vertical-margin);
  }
  &__heading {
    margin-bottom: var(--small-vertical-margin);
    font-family: var(--heading-font);
    font-size: var(--xxx-large-font-size);
    line-height: 1;
  }
  &__text {
    font-size: var(--medium-font-size);
  }
}

/* First layer */
$num-elements: 8;
$angle: (calc(360 / $num-elements));
$rot: 90;
$circle-size: 44px;

/* Second layer */
$angle2: (calc(360 / $num-elements));
$rot2: 22.5;

.scroll-dots {
  --radius: 200px;
  @include for-small-tablet-below {
    --radius: 160px;
  }
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  &__text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    font-style: italic;
  }
  &__container {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // width: 100%;
    // aspect-ratio: 1 / 1;
    // transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--radius);
    height: var(--radius);
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  &__dot {
    width: $circle-size;
    height: $circle-size;
    background: var(--grey);
    top: 50%;
    left: 50%;
    position: absolute;
    border-radius: 50%;
    margin: -(calc($circle-size / 2));
    &:first-of-type {
      background: var(--darkgrey);
    }
  }
  &__center-dot {
    position: relative;
    background: var(--grey);
    top: 50%;
    left: 50%;
    position: absolute;
    border-radius: 50%;
    width: calc($circle-size * 1.8);
    height: calc($circle-size * 1.8);
    margin: -(calc(calc($circle-size * 1.8) / 2));
    z-index: 1;
    .white-radial-gradient {
      position: absolute;
      height: 100%;
      width: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #fff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0;
    }
  }
}

/* First layer */
@for $i from 1 through $num-elements {
  .scroll-dots__container .scroll-dots__dot--first-layer:nth-child(#{$i}) {
    transform: rotate($rot * 1deg)
      translate(calc(var(--radius) / 2))
      rotate($rot * -1deg);
  }
  $rot: $rot + $angle;
}

/* Second layer */
@for $i from 9 through 17 {
  .scroll-dots__container .scroll-dots__dot--second-layer:nth-child(#{$i}) {
    transform: rotate($rot2 * 1deg)
      translate(calc(var(--radius) / 1.2))
      rotate($rot2 * -1deg);
  }
  $rot2: $rot2 + $angle;
}

/* simple-hover
---------------------------------------------- */
.simple-hover {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @include for-phone-below {
      grid-template-columns: 1fr;
      gap: var(--medium-vertical-margin);
    }
  }
  &__buttons-column {
    grid-column: 1 / span 5;
    @include for-phone-below {
      grid-column: initial;
    }
  }
  &__tabs-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: 7 / span 6;
    align-self: stretch;
    @include for-phone-below {
      grid-column: initial;
    }
  }
  .simple-hover__text {
    font-size: var(--medium-font-size);
    line-height: var(--standard-line-height);
  }
  .square-button {
    justify-content: initial;
    margin-top: var(--medium-vertical-margin);
  }
}
/* simple-image-hover
---------------------------------------------- */
.simple-image-hover {
  .square-button {
    margin-top: var(--medium-vertical-margin);
    justify-content: start;
  }
}

/* tabs
---------------------------------------------- */
.tab-body-content {
  display: none;
  opacity: 0;
  transition: opacity 1s;
  &.show-tab {
    opacity: 1;
    display: block;
    animation-name: example;
    animation-duration: 1.2s;
    animation-timing-function: ease;
  }
}

@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* team
---------------------------------------------- */
.team {
  &__heading {
    font-family: var(--heading-font);
    line-height: 1;
    font-size: var(--x-large-font-size);
    margin-bottom: var(--large-vertical-margin);
    text-align: center;
    &--x-large-width {
      max-width: var(--x-large-width);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--medium-grid-gap);
  }
}
.team-member {
  width: calc(25% - calc(var(--medium-grid-gap)));
  margin-bottom: var(--medium-vertical-margin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  @include for-tablet-landscape-below {
    width: calc(33.33% - calc(var(--medium-grid-gap) / 2));
  }
  @include for-small-tablet-below {
    width: calc(50% - calc(var(--medium-grid-gap) / 2));
  }
  @include for-phone-below {
    width: 100%;
  }
  &__circle-image {
    margin-bottom: var(--small-vertical-margin);
    display: flex;
    justify-content: center;
  }
  &__name {
    font-family: var(--sans-serif-font);
    font-size: var(--preheading-font-size);
    text-transform: uppercase;
    margin-bottom: 0.1em;
  }
  &__type {
    color: var(--darkgrey);
    font-size: var(--small-font-size);
    font-style: italic;
    margin-bottom: calc(var(--x-small-vertical-margin) * 1.5);
  }
  &__location {
    font-size: var(--small-font-size);
    margin-bottom: var(--x-small-vertical-margin);
  }
  &__modal-button {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    @include underlined-link;
  }
  &__modal-info {
    display: none;
  }
}
.team-member-modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  cursor: pointer;
  &__wrapper {
    background-color: var(--white);
    position: absolute;
    // top: var(--lr-page-padding);
    overflow: scroll;
    width: 50%;
    max-height: calc(100vh - calc(var(--lr-page-padding) * 2));
    max-height: calc(100svh - calc(var(--lr-page-padding) * 2));
    cursor: initial;
    @include for-small-tablet-below {
      width: 75%;
      // left: 12.5%;
      transform: initial;
    }
    @include for-phone-below {
      width: 80%;
      // left: 10%;
    }
  }
  &__container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // overflow: scroll;
    height: 100%;
  }
  &__content {
    padding: var(--lr-page-padding) calc(var(--lr-page-padding) * 1.5);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__close {
    position: sticky;
    top: 5px;
    padding: 15px;
    left: 100%;
    cursor: pointer;
    line {
      stroke: var(--black);
    }
  }
  &__circle-image {
    margin-bottom: var(--small-vertical-margin);
    display: flex;
    justify-content: center;
  }
  &__name {
    font-family: var(--sans-serif-font);
    font-size: var(--preheading-font-size);
    text-transform: uppercase;
    margin-bottom: 0.1em;
  }
  &__type {
    font-size: var(--x-small-font-size);
    margin-bottom: var(--small-vertical-margin);
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__location {
    font-size: var(--small-font-size);
    margin-bottom: var(--small-vertical-margin);
  }
  &__info-item {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    margin-bottom: calc(var(--x-small-vertical-margin) * 0.5);
    a {
      color: inherit;
      @include underlined-link;
    }
  }
  &__linkedin {
    margin-top: var(--x-small-vertical-margin);
  }
  &__bio {
    margin-top: var(--small-vertical-margin);
  }
  &__bio-title {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    margin-bottom: var(--x-small-vertical-margin);
    text-align: center;
  }
}

/* testimonial
---------------------------------------------- */
.testimonials {
  &__title {
    margin-bottom: var(--large-vertical-margin);
    h2 {
      font-size: var(--xxx-large-font-size);
      color: var(--darkgrey);
      text-align: center;
    }
    .dots {
      margin-top: var(--small-vertical-margin);
      margin-bottom: var(--small-vertical-margin);
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      div {
        height: 10px;
        width: 10px;
        background-color: var(--white);
        border: 2px solid transparent;
        border-radius: 50%;
        &:first-of-type {
          background-color: var(--black);
        }
      }
    }
  }
  &__carousel {
    .splide__track {
      @include gradient-mask-image;
    }
    .splide__pagination {
      top: -5rem;
      bottom: initial;
      @include for-large-tablet-portrait-below {
        top: -3.5rem;
      }
      &__page {
        background-color: var(--white);
        opacity: 1;
        &.is-active {
          background-color: var(--black);
        }
      }
    }
  }
}
.testimonial {
  &__text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--medium-vertical-margin);
    max-width: var(--x-large-width);
    font-size: var(--large-font-size);
    text-align: center;
  }
  &__circle-image {
    margin-bottom: var(--small-vertical-margin);
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .partner-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/* testimonials-with-media
---------------------------------------------- */
.testimonials-with-media {
  &__wrapper {
    padding-bottom: var(--medium-vertical-margin);
    &--border-top {
      padding-top: var(--medium-vertical-margin);
      border-top: 1px solid var(--border-color);
    }
    &--border-bottom {
      border-bottom: 1px solid var(--border-color);
    }
  }
  &__carousel {
    position: relative;
    width: 100%;
  }
  &__fixed-title {
    position: absolute;
    top: 0;
    z-index: 1;
    @include for-small-tablet-below {
      position: initial;
    }
    h2 {
      font-size: var(--xx-large-font-size);
      width: 48vw;
      max-width: 500px;
      @include for-small-tablet-below {
        width: initial;
      }
    }
    &--small {
      h2 {
        font-size: calc(var(--large-font-size) * 1.4);
      }
    }
    &--medium {
      h2 {
        font-size: var(--x-large-font-size);
      }
    }
  }
  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(2 * var(--lr-page-padding));
    @include for-small-tablet-below {
      grid-template-columns: 1fr;
    }
  }
  &__text-column {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  &__text {
    font-size: var(--large-font-size);
    padding-top: var(--x-large-vertical-margin);
    margin-bottom: var(--medium-vertical-margin);
    @include for-small-tablet-below {
      padding-top: initial;
      margin-bottom: var(--medium-vertical-margin);
    }
  }
  &__image-column {
    @include for-small-tablet-below {
      order: -1;
    }
    img {
      aspect-ratio: 1 / 1.2;
      object-fit: cover;
      width: 100%;
    }
    &--video {
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      iframe {
        width: 100%;
        aspect-ratio: 1.7 / 1;
        object-fit: cover;
      }
    }
  }
  .testimonial-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
  }
  .partner-info {
    @include for-small-tablet-below {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .splide__pagination {
    justify-content: start;
    margin-top: 2rem;
    padding-left: 0;
    position: initial;
    @include for-small-tablet-below {
      margin-bottom: 2rem;
    }
    &__page {
      background-color: transparent;
      opacity: 1;
      border: 1px solid var(--black);
      &.is-active {
        background-color: var(--black);
      }
    }
  }
  .splide__arrow--prev {
    display: none;
  }
}
/* text-banner
---------------------------------------------- */
.text-banner {
  padding-top: 28vh;
  &__inner {
    $self: &;
    max-width: var(--x-large-width);
    &#{ $self }--text-align-center {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .h1 {
      font-family: var(--heading-font);
      font-size: var(--xx-large-font-size);
    }
  }
}

/* text-group
---------------------------------------------- */
.text-group {
  &__heading {
    font-family: var(--heading-font);
    line-height: 1;
  }
}

/* text-group
---------------------------------------------- */
.writer {
  line-height: var(--standard-line-height);
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
  ul {
    list-style: none;
    margin-left: 0.5em;
    li {
      &::marker {
        content: "• ";
        font-size: 0.9em;
      }
      /** Safari */
      // @media not all and (min-resolution: 0.001dpcm) {
      //   &::marker {
      //     content: initial;
      //   }
      // }
      &:not(:last-of-type) {
        margin-bottom: 0.2em;
      }
    }
  }
}

/* three-column-gallery
---------------------------------------------- */
.three-column-gallery {
  overflow: clip;
  overflow: hidden;
  max-width: 100vw;
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--medium-grid-gap);
    margin-left: -10vw;
    margin-right: -10vw;
    @include for-small-tablet-below {
      margin-left: -20vw;
      margin-right: -20vw;
    }
  }
  &__item {
    img {
      height: 45vw;
      object-fit: cover;
      @include for-small-tablet-below {
        height: 60vw;
      }
    }
  }
}

/* timeline
---------------------------------------------- */
.timeline {
  $self: &;
  background-color: var(--black);
  color: var(--white);
  padding: var(--small-vertical-margin) 0 var(--large-vertical-margin) 0;
  &__image-container {
    padding: 7vw;
    @include for-small-tablet-below {
      padding: var(--lr-page-padding);
    }
  }
  .splide__slide {
    &.is-active {
      img {
        transform: scale(1.5);
        @include for-small-tablet-below {
          transform: initial;
        }
      }
      #{ $self }__dot {
        height: 15px;
        width: 15px;
        @include for-small-tablet-below {
          height: 10px;
          width: 10px;
        }
      }
    }
  }
  .splide__arrow {
    @include for-phone-below {
      top: 94vw;
    }
  }
  img {
    aspect-ratio: 1.2 / 1;
    object-fit: cover;
    transition: transform 0.6s ease-out;
    @include for-small-tablet-below {
      transition: initial;
    }
    padding-top: 2rem;
  }
  &__dot-container {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    height: 1.5rem;
  }
  &__dot-container::before,
  &__dot-container::after {
    content: "";
    height: 0.5px;
    width: 100%;
    background-color: var(--white);
    display: block;
  }
  &__dot {
    margin: 0 0.4rem;
    background-color: var(--white);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    transition: width 0.6s ease-out, height 0.6s ease-out;
  }
  &__year {
    text-align: center;
    margin-top: 2rem;
    font-family: var(--sans-serif-font);
    font-size: 1.1rem;
    min-height: 1.2em;
  }
  &__caption-container {
    display: flex;
    justify-content: center;
    @include for-phone-below {
      justify-content: initial;
    }
  }
  &__caption {
    text-align: center;
    padding: 0 var(--lr-page-padding);
    max-width: 500px;
    line-height: var(--standard-line-height);
    font-size: var(--x-small-font-size);
    line-height: var(--standard-line-height);
    @include for-phone-below {
      padding: 0 var(--lr-page-padding);
      max-width: initial;
      text-align: initial;
    }
  }
}
/* two-column-gallery
---------------------------------------------- */
.two-column-gallery {
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--medium-grid-gap);
  }
  &__item {
    img {
      height: 45vw;
      object-fit: cover;
      @include for-phone-below {
        height: 65vw;
      }
    }
  }
}
/* two-column-mix
---------------------------------------------- */
.two-column-mix {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @include for-small-tablet-below {
    grid-template-columns: 1fr;
    gap: var(--medium-vertical-margin);
  }
  &__column-a {
    grid-column: 1 / span 6;
    @include for-small-tablet-below {
      grid-column: initial;
    }
  }
  &__column-b {
    grid-column: 9 / span 4;
    @include for-small-tablet-below {
      grid-column: initial;
    }
  }
  &__heading {
    font-size: var(--xxx-large-font-size);
    margin-bottom: calc(var(--small-vertical-margin) + 1rem);
  }
  &__paragraph {
    font-size: var(--x-large-font-size);
  }
  &__image {
    margin-top: var(--x-large-vertical-margin);
    @include for-small-tablet-below {
      margin-top: var(--medium-vertical-margin);
    }
  }

  .regions-list {
    columns: 2;
  }
  .region {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
    li:not(:last-of-type) {
      margin-bottom: 0.3rem;
    }
  }
  .region-title {
    margin-bottom: 1.5rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  .country {
    &__link {
      color: inherit;
    }
  }
  .button-container {
    margin-top: var(--medium-vertical-margin);
  }
  .square-button {
    margin-top: var(--x-small-vertical-margin);
    justify-content: initial;
    @include for-small-tablet-below {
      margin-top: var(--small-vertical-margin);
    }
  }
}
/* two-column-text-media
---------------------------------------------- */
.two-column-text-media {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  @include for-phone-below {
    grid-template-columns: 1fr;
    gap: var(--small-vertical-margin);
  }
  &__text {
    grid-column: 1 / span 6;
    @include for-phone-below {
      grid-column: initial;
    }
  }
  &__media {
    grid-column: 8 / span 5;
    @include for-phone-below {
      grid-column: initial;
      order: -1;
    }
  }
  &--text-second {
    .two-column-text-media__text {
      grid-column: 7 / span 6;
      @include for-phone-below {
        grid-column: initial;
      }
    }
    .two-column-text-media__media {
      grid-column: 1 / span 5;
      order: -1;
      @include for-phone-below {
        grid-column: initial;
      }
    }
  }
  &__images {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    picture {
      display: block;
      &:not(:last-of-type) {
        margin-bottom: var(--x-small-vertical-margin);
      }
    }
    img,
    video {
      width: 100%;
      aspect-ratio: 1.7 / 1;
      object-fit: cover;
    }
  }
  .text-group__preheading {
    margin-bottom: var(--small-vertical-margin);
  }
  .text-group__heading {
    margin-bottom: calc(var(--small-vertical-margin) - 1rem);
    font-size: var(--x-large-font-size);
  }
  .text-group__paragraph {
    line-height: var(--standard-line-height);
    &--large-font-size {
      font-size: var(--large-font-size);
    }
    &--medium-font-size {
      font-size: var(--small-font-size);
    }
  }
}
/* two-column-text
---------------------------------------------- */
.two-column-text {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @include for-phone-below {
    grid-template-columns: 1fr;
  }
  > :first-child {
    grid-column: 1 / span 5;
    @include for-phone-below {
      grid-column: initial;
    }
  }
  > :last-child {
    grid-column: 7 / span 6;
    @include for-phone-below {
      grid-column: initial;
    }
  }
  .text-group__heading {
    font-size: var(--x-large-font-size);
    grid-column: span 5;
    @include for-phone-below {
      grid-column: initial;
      margin-bottom: var(--small-vertical-margin);
    }
  }
  .text-group__paragraph p {
    font-size: var(--medium-font-size);
    line-height: var(--standard-line-height);
  }
  .preheading {
    margin-bottom: var(--xx-small-vertical-margin);
  }
}

/* legal-text
---------------------------------------------- */
.legal-text {
  max-width: var(--xx-large-width);
}
/* block
---------------------------------------------- */
.block {
  figcaption {
    margin-top: 0.5em;
    font-size: var(--xx-small-font-size);
  }
  &:not(.block-type-pdf) a {
    color: var(--black);
    text-decoration-color: var(--black);
    @include underlined-link;
  }
  &:not(:last-of-type) {
    margin-bottom: var(--small-vertical-margin);
  }
}
/* block-type-text
---------------------------------------------- */
.block-type-text {
  p {
    line-height: var(--standard-line-height);
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }
}
/* block-type-video
---------------------------------------------- */
.block-type-video {
  max-width: var(--small-width);
  margin: var(--medium-vertical-margin) auto var(--small-vertical-margin);
  &__video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/* block-type-image
---------------------------------------------- */
.block-type-image {
  max-width: var(--small-width);
  margin: var(--medium-vertical-margin) auto var(--small-vertical-margin);
}
/* block-type-heading
---------------------------------------------- */
.block-type-heading {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--sans-serif-font);
    color: var(--darkgrey);
    text-align: center;
  }
  h2 {
    margin-top: calc(var(--small-vertical-margin) * 1.3);
    font-size: calc(var(--preheading-font-size) * 1.1);
  }
  h3 {
    margin-top: calc(var(--small-vertical-margin) * 1.2);
    font-size: calc(var(--preheading-font-size) * 1);
  }
  h4 {
    margin-top: calc(var(--small-vertical-margin) * 1.1);
    font-size: calc(var(--preheading-font-size) * 0.9);
  }
  h5 {
    margin-top: calc(var(--small-vertical-margin) * 1);
    font-size: calc(var(--preheading-font-size) * 0.8);
  }
}
/* block-type-list
---------------------------------------------- */
.block-type-list {
  ul {
    list-style: initial;
    margin-left: 1em;
  }
  ol {
    list-style-type: decimal;
    margin-left: 1.1em;
  }
  li:not(:last-of-type) {
    margin-bottom: 1em;
  }
}

@mixin page-width {
  max-width: var(--page-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}

@mixin underlined-link {
  text-decoration: underline;
  /* text-underline-position: under; */
  text-underline-position: intial;
  text-decoration-thickness: .06em;
  transition: text-decoration-color .4s ease;
  &:hover{
    text-decoration-color: transparent;
  }
}

@mixin navbar-link {
  font-size: var(--xx-small-font-size);
  transition: color .4s ease;
  display: block;
  padding: 8px calc(var(--lr-page-padding)/2);
  color: var(--black);
}

@mixin gradient-mask-image {
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  @include for-phone-below {
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 15%,
      hsl(0 0% 0% / 1) 85%,
      hsl(0 0% 0% / 0)
    );
  }
}
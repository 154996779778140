/* Setting Media queries
---------------------------------------------- */
@mixin for-phone-below {
  @media (max-width: 599px) { @content; }
}
@mixin for-small-tablet-below {
  @media (max-width: 850px) { @content; }
}
@mixin for-large-tablet-portrait-below {
  @media (max-width: 1024px) { @content; }
}
@mixin for-tablet-landscape-below {
  @media (max-width: 1200px) { @content; }
}
@mixin for-desktop-below {
  @media (max-width: 1800px) { @content; }
}
@mixin for-large-desktop-up {
  @media (min-width: 1801px) { @content; }
}